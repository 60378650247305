<template>
<div class="bg-scorelitgray z-50">
    <div class="py-3 relative z-10 shadow flex justify-around h-full w-screen md:px-8">
        <div class="text-white text-xs font-medium my-auto w-1/3">
            &copy; 2020 | Made in the Netherlands
        </div>

        <div class="flex-initial flex justify-center my-auto w-1/3">
            <img class="h-5" src="@/assets/images/logo/emblem.svg" alt="Scorelit logo">
        </div>

        <div class="text-white text-xs my-auto flex justify-end w-1/3">
            <a href="/help" class="h-full pr-8 font-medium transition duration-300 hover:text-gray-400">
                Help
            </a>
            <a href="https://www.scorelit.com/voorwaarden/" target="_blank" class="h-full pr-l font-medium transition duration-300 hover:text-gray-400">
                Algemene voorwaaden
            </a>
            <a href="https://www.scorelit.com/privacy-statement/" target="_blank" class="h-full pl-8 font-medium transition duration-300 hover:text-gray-400">
               Privacy Statement
           </a>
        </div>
    </div>
</div>
</template>

<script>

export default {
    name: 'Footer',

}
</script>