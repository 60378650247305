<template>
    <div>
        <label v-if="label" :for="label" class="block text-xs sm:text-sm leading-5 font-medium" :class="theme === 'dark' ? 'text-white' : 'text-gray-900' ">{{ label }}</label>
        <div class="mt-1 relative rounded-md shadow-sm" :class="{'border-grays-400 border': border === 'enable'}">
            <div class="relative text-gray-400 focus-within:text-gray-600">
                
                <div class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center ">
                <!-- Heroicon name: solid/search -->
                    <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
                    </svg>
                </div>
            
                <input ref="input" :id="label" type="search" :value="value" :placeholder="placeholder" :disabled="!!disabled" :autofocus="autofocus !== undefined" 
                       class="form-input block w-full p-2 sm:py-4 pl-10 text-xs sm:text-sm sm:leading-5 rounded-md shadow-sm" :class="theme === 'dark' ? 'bg-scorelitgray text-gray-300': 'bg-white' ">   
                </div>
        </div>
    </div>
 
</template>

<script>
export default {
    name: "TextInput",
    props: ['value', 'label', 'placeholder', 'disabled', 'autofocus', 'theme', 'border'], 
    
}
    
</script>